@import "vars"
@import "common"

.aboutUs
  @media (max-width: $md)
    padding: 0 $mobile-wrapper-padding
  
  .mountain
    position: absolute
    right: 0
    width: 69%
    bottom: -5px
    z-index: -1

    &__img
      width: 100%

    @media (max-width: $lg)
      width: 80%

    @media (max-width: $md)
      display: none

  .path
    position: absolute
    width: 55%
    top: 10%
    left: 40%

  .section-img
    height: 50%
    bottom: 5rem
    right: 0
    top: unset

    img
      object-fit: contain
      height: unset
      width: unset

  &__content
    /* <-- */
    height: 1px
    overflow: visible
    /* --> нужно задать любую высоту в пикселях, чтобы экран не перелистывался при скролле */
    width: 45%
    
    @media (max-width: $md)
      height: unset
      width: unset

    h3
      margin-bottom: 5rem

  .work-stages
    display: flex
    flex-direction: column
    padding-right: 2rem
    height: 50vh
    overflow-y: scroll

    @media (max-width: $md)
      padding: 0
      position: relative
      height: unset
      overflow: initial
      background: url("../static/img/column_img.png") 
      background-position: center 10%
      background-repeat: no-repeat
      background-size: contain

    .arrow-button
      margin-left: 3rem
      cursor: default

    &__order
      color: $red

    &__title
      display: flex
      gap: 3rem
      align-items: baseline
      margin-bottom: 1.3rem

      @media (max-width: $md)
        margin-bottom: 1rem 

    &__section
      transition-duration: .5s

      @media (max-width: $md)
        background-color: $info-block-color
        backdrop-filter: blur(4px)
        -webkit-backdrop-filter: blur(4px)
        padding: 1.5rem
        border-left: 2px solid $red

      &.hide-xl
        @media (min-width: $xl)
          display: none 

      .caption
        font-size: 1.6rem
        font-weight: 500

      .body-text
        line-height: 1.2
        font-size: 1.3rem

        @media (max-width: $md)
          font-size: 1rem

      &:not(:last-of-type)
        margin-bottom: 3rem

        @media (max-width: $md)
          margin-bottom: 1rem

  .cards-anchor
    position: relative
    height: 100%

    .dot
      position: absolute
      width: 1%
      height: 2%
      background-color: white
      border-radius: 50%
      z-index: 2

      .card-anchor
        position: relative

        .card 
          position: absolute
          top: 0
          left: 0 
          display: flex
          flex-direction: column
          gap: .3rem
          min-width: 13vw

          .caption 
            font-size: 1.5rem
            font-weight: 600
            text-transform: uppercase


      &:nth-of-type(1)
        left: 61%
        top: 10%

        .card 
          transform: translate(-100%, -90%) 

      &:nth-of-type(2)
        left: 71.5%
        top: 34%

        .card
          transform: translate(6%, -100%)

      &:nth-of-type(3)
        left: 93.5%
        top: 70.5%

        .card
          transform: translate(-78%, -100%)

      &:nth-of-type(4)
        left: 52%
        top: 68.5%

        .card
          transform: translate(-13%, 16%)

      &:nth-of-type(5)
        left: 40%
        top: 84.5%

        .card
          transform: translate(-100%, -50%)
      
.js-scroll-view
  transition-duration: .5s

  &_hidden 
    opacity: 0